import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";

import usePromise from "../../../hooks/usePromise/usePromise";
import { widgetNetworksAPI } from "../../../services/widgetServices";
import NetworkStyles from "./NetworkStyles";
import EmptyListWithImage from "../../../components/EmptyListWithImage/EmptyListWithImage";
import { InfoPanel } from "../../../components/InfoPanel/InfoPanel";
import ButtonCustom from "../../../components/ButtonCustom/ButtonCustom";
import JobsListingEffect from "../../../components/effects/JobsListingEffect/JobsListingEffect";
import WidgetOverlay from "../WidgetOverlay/WidgetOverlay";

import EmptyNetworkImage from "../../../assets/svg/discover_no_people.svg";

const TOTAL_RECORDS_COUNT = 10;
const VISIBLE_RECORDS_COUNT = 5;

const Network = ({ token }) => {
  const [networks, setNetworks] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [callingWidgetNetworksAPI, refreshWidgetNetworksAPI] =
    usePromise(widgetNetworksAPI);
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedUsername, setSelectedUsername] = useState("");

  useEffect(() => {
    refreshWidgetNetworksAPI(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (
      callingWidgetNetworksAPI.hasFetched() &&
      callingWidgetNetworksAPI.hasErrors() === false &&
      callingWidgetNetworksAPI.data()?.data
    ) {
      const { networks_list = [] } = callingWidgetNetworksAPI.data()?.data;
      setNetworks(networks_list.slice(0, TOTAL_RECORDS_COUNT) ?? []);
      setShowLoader(false);
    } else if (
      callingWidgetNetworksAPI.hasFetched() &&
      callingWidgetNetworksAPI.hasErrors() === true
    ) {
      setNetworks([]);
      setShowLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingWidgetNetworksAPI.isFetching()]);

  const onMessageClick = (record) => {
    setShowOverlay(true);
    setSelectedUsername(record.full_name.replace(record.last_name, ""));
  };

  const closeOverlay = () => {
    setShowOverlay(false);
    setSelectedUsername("");
  };

  return (
    <NetworkStyles>
      <div className="network-tab-content-wrapper">
        <div className="widget-network-tab">
          <h2 className="title">Meet students, alumni, mentors, and more</h2>
          <div className="network-list-wrapper">
            {showLoader ? (
              <JobsListingEffect count={5} />
            ) : networks && !isEmpty(networks) ? (
              <>
                {networks.map((item, index) => {
                  let tags = [];
                  if (item?.account_type === "alumni") {
                    tags.push(item?.account_type);
                  }
                  if (item.user_type && item.user_type.includes("Ambassador")) {
                    tags.push("Ambassador");
                  }
                  if (item?.mentor) {
                    tags.push("Mentor");
                  }
                  let subTitle = [];
                  if (item?.major) subTitle.push(item.major);
                  if (item?.graduation_year)
                    subTitle.push(item.graduation_year);
                  return (
                    <div className="network-card">
                      {index >= VISIBLE_RECORDS_COUNT && (
                        <div className="overlay-card" />
                      )}
                      <InfoPanel
                        variant="discover-network"
                        key={index}
                        index={index}
                        avatar={item.user_profile}
                        userAvatar={true}
                        title={item.full_name}
                        subTitle={subTitle}
                        tags={tags}
                        isFavorites={false}
                        onClick={(e) => {}}
                        onPanelClick={(e) => {}}
                        intro={
                          item.country_image !== "" && (
                            <>
                              <div className="c-flag">
                                <img
                                  src={item.country_image}
                                  height="15px"
                                  width="22px"
                                  alt={`Flag of ${item.country_of_origin}`}
                                />
                              </div>
                              <div style={{ marginLeft: "8px" }}>
                                {item.country_of_origin}
                              </div>
                            </>
                          )
                        }
                        action={
                          <ButtonCustom
                            height={30}
                            width={100}
                            className={"bg-widget"}
                            onClick={() => onMessageClick(item)}
                            aria-label={`Message ${item.full_name}`}
                          >
                            Message
                          </ButtonCustom>
                        }
                        marginTop="0px"
                      />
                    </div>
                  );
                })}
              </>
            ) : (
              <EmptyListWithImage
                Image={EmptyNetworkImage}
                title={"Looks like there are no people."}
                containerClassname={"empty-discover-people"}
              />
            )}
          </div>
        </div>

        {showOverlay && (
          <WidgetOverlay
            title={`Create a free account to chat with ${selectedUsername}`}
            subtitle={`Send ${selectedUsername} a message and get notified when they reply.`}
            closeOverlay={closeOverlay}
            route_type="network"
          />
        )}
      </div>
    </NetworkStyles>
  );
};

export default Network;
