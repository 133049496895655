import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";

import EmptyChatViewStyles from "./EmptyChatViewStyles";
import { checkIsBYUUser } from "../../../../helper/helper";

const EmptyChatView = () => {
  const { users = {} } = useSelector((store) => store.userStore);

  const IsBYUUser = useMemo(() => {
    return checkIsBYUUser(users?.school_id);
  }, [users]);

  return (
    <EmptyChatViewStyles>
      <div className="empty-chat-view">
        <h4>Welcome to Interstride’s Messaging Portal!</h4>
        <img src="/images/welcome.svg" width="400px" alt="" />
        <p>
          {IsBYUUser
            ? "Connect with other BYU-Pathway students and alumni. Whether you are looking to network for career opportunities, ask for advice about a degree program, or make local connections — this is the place for you."
            : "This is the place to connect with our international student community. Whether you are looking for guidance on the application process or navigating life in the U.S., you can find it here from our international students."}
        </p>
      </div>
    </EmptyChatViewStyles>
  );
};

export default memo(EmptyChatView);
